.App {
  text-align: center;
}

.App-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

.App-table th{
  width: 200px;
  font-weight: normal;
}

.App-table caption {
  height: 100px;
  font-size: 25px;
}

.App-table .Table-header {
  height: 100px;
}

.App-table tr {
  height: 50px;
}

.App-table td.red {
  color: red;
}

.App-table td.green {
  color: green;
}

@media (max-width: 1000px) {
  .App-table {
    font-size: 16px;
  }
  .App-table th{
    width: 100px;
    font-weight: normal;
    padding: 10px;
  }

  .App-table td {
    padding: 10px;
  }
  
  .App-table caption {
    height: 100px;
    font-size: 20px;
  }
}

@media (max-width: 700px) {
  .App-table {
    font-size: 14px;
  }
  .App-table th{
    width: 70px;
    font-weight: normal;
    padding: 10px;
  }

  .App-table td {
    padding: 10px;
  }
  
  .App-table caption {
    height: 100px;
    font-size: 18px;
  }
  
  .App-table .Table-header {
    height: 80px;
  }
  
  .App-table tr {
    height: 30px;
  }
}

@media (max-width: 500px) {
  .App-table {
    font-size: 12px;
  }
  .App-table th{
    width: 40px;
    font-weight: normal;
    padding: 2px;
  }

  .App-table td {
    padding: 2px;
  }
  
  .App-table caption {
    height: 100px;
    font-size: 16px;
  }
  
  .App-table .Table-header {
    height: 50px;
  }
  
  .App-table tr {
    height: 20px;
  }
}

